<!--
 * @Descripttion:
 * @version:
 * @Author: hutian
 * @Date: 2021-01-07 17:37:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-02-26 16:56:56
-->
<template>
  <div class="tag-list">
    <a-modal class="tag-form" title="查看商品" style="top: 8px" width="70%" v-model="visible"
             :maskClosable="false" :footer="null">
      <a-table :columns="columns" :data-source="dataList">
        <span slot="action" slot-scope="item, row">
          <a :disabled="handle == 'check'" @click="delByIds(row)">删除</a>
        </span>
        <span slot="imgUrl" slot-scope="item">
          <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
        </span>
      </a-table>
    </a-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      dataList: [],
      columns: [
        {
          title: '商品名称',
          dataIndex: 'name',
          width: 200,
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '商品图标',
          dataIndex: 'imgUrl',
          width: 100,
          scopedSlots: { customRender: 'imgUrl' },
        },
        {
          title: '分类',
          dataIndex: 'categoryName',
          width: 200,
        },
        {
          title: '品牌',
          dataIndex: 'brandName',
          width: 150,
        },
        {
          title: '规格型号',
          dataIndex: 'barCode',
          width: 200,
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 60,
          scopedSlots: { customRender: 'action' },
        },
      ],
    }
  },
  methods: {
    isShow(item) {
      this.visible = true
      this.dataList = item.tagGoodsList
    },
    toSubmit() {
      this.visible = false
    },
    delByIds(row){
        const tagId = row.id;
        this.$confirm({
          title: '确定删除该商品?',
          onOk: () => {
            this.axios.post('/api/product/system/tagGoods/del/'+tagId).then(res => {
              if(res.code == 200) {
                this.$message.success(res.message)
                this.visible = false
                this.$emit('change')
              }
            })
          }
        })
      }
  },
}
</script>

<style lang="scss">
</style>
